.banner-carousel {
  overflow: hidden;
  width: 100vw;
  height: 46.667vw;
  padding: 0;
  margin: 0 !important;
}
.space-carousel{
  width: 100vw;
  height: 46.867vw;
  overflow: hidden;
  padding: 0;
  margin: 0 !important;
}
.banner-carousel .slider,.space-carousel .slider{
  min-height: 100%;
}
.title_img{
  text-align: center;
  margin: 12.8vw 0 8.533vw;
}
.title_img img{
  width: 83.2vw;
  height: 11.733vw;
}
.countries{
  display: flex;
  padding: 0 5.333vw;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

video{
  width: 100%;
  height: 56vw;
}
.countries .item{
  position: relative;
  margin-bottom: 4vw;
}
.countries img{
  width: 42.667vw;
  height: 26.667vw;
}
.countries .child{
  /* opacity: 0.4; */
/* background: #000000; */
  position: absolute;
  left: 0;
  top: 8vw;
  width: 42.667vw;
  height: 10.667vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 10px;
color: #FFFFFF;
}
.countries .child p{
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
}
.countries .title{
  font-size: 4vw;
  color: #fff;
  text-align: center;
  height: 8.533vw;
  line-height: 8.533vw;
  margin:0;
  margin-bottom: 2.133vw;
  font-weight:600;
}