body{
  position: relative;
}
.content_box{
  min-height: 100vh;
  padding-bottom: 125vw;
  box-sizing: border-box;
  background: #fff;
}
.footer_box{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: #fff;
}
.nav_bar{
    height: 12.8vw;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    background: #fff;
}
.nav_bar_box{
  height: 12.8vw;
  width: 100vw;
  
}
.nav_bar .mask{
  width: 100vw;
  height: 100vh;
  display: none;
}
.nav_bar .caidan{
  width:5.333vw;
  height: 5.333vw;
  position: relative;
  padding: 0 6.4vw;

}
.nav_bar .caidan img{
  width:5.333vw;
  height: 5.333vw;
}
.nav_bar .caidan .nav_list{
  display: none;
  position: absolute;
  left: 6.4vw;
  top: 9.067vw;
  background: rgba(0, 0, 0, 0.6);
  width: 26.667vw;
  z-index: 999;
}
.nav_bar .caidan .nav-check{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.nav_bar .caidan .nav-check:checked{
  width: 100vw;
  height: 100vh;
}
.nav-check:checked ~ .nav_list{
  display: block;
}
.nav-check:checked ~ .mask{
  display: block;
}
.nav_bar .caidan .nav_list a,.nav_bar .caidan .nav_list span{
  display: inline-block;
  width: 26.667vw;
  height: 11.733vw;
  line-height: 11.733vw;
  text-align: center;
  font-size: 14px;
  color: #FFFFFF;
}
.nav_bar .caidan .nav_list{

}
.nav_bar .logo{
    width: 32.533vw;
    height: 5.867vw;
}
.footer{
  background: #000000;
  width: 100vw;
  padding: 12.8vw 0;

}
.footer a,.footer p{
  display: inline-block;
  width: 100vw;
  text-align: center;
  font-size: 3.2vw;
  color: #FFFFFF;
  padding: 0;
  margin: 0;
  height: 7.467vw;
  line-height: 7.467vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .name{
  margin-bottom: 3.2vw;
}
.footer a img,.footer p img{
  width: 2.667vw;
  height: 2.667vw;
  margin-right: 1.067vw;
}
.footer .imgs{
  margin-top: 8.533vw;
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #B0B0B0;
  text-align: center;

}
.footer .imgs img{
  width: 21.333vw;
  height: 21.333vw;
  margin-bottom: 4.267vw;
}
.footer .imgs div{
  padding:0 10.667vw ;
  width: 21.333vw;
}
.footer .imgs div div{
  padding: 0;
}
video{
  background: #000;
  width: 100%;
  z-index: 998;
  object-fit:fill
}
