.vlog_list{
    padding: 0 5.333vw;
    background: #fff;
    padding-bottom: 4.267vw;
}
.vlog_list .title{
    font-size: 14px;
    color: #000000;
    margin: 0;
    padding: 4.267vw 0;
    text-align: center;
    overflow: hidden;
    font-weight: 600;
}
.vlog_list .item{
    overflow: hidden;
    padding-top: 4.267vw;
}
