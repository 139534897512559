.tabs{
    background: #000000;
    width: 100vw;
    height: 10.667vw;
    display: flex;
    justify-content: space-around;
}
.tabs span{
    height: 10.667vw;
    line-height: 10.667vw;
    color: #fff;
    position: relative;
    width: 60px;
    text-align: center;
}
.tabs span a{
    height: 10.667vw;
    line-height: 10.667vw;
    color: #fff;
    position: relative;
    width: 60px;
    text-align: center;
}
.tabs .active{
    font-size: 4.267vw;
}
.tabs .active::after{
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1px;
    border: 1.333vw solid;
    border-color: #000 #000 #FF0000 #000 ;
}
.album_list{
    background: #fff;
    padding-bottom:  8.533vw;
}
.album_list .item{
    padding:  8.533vw 5.333vw 0; 
    margin: 0;
    overflow: hidden;
}
.album_list .item img{
    height: 67.2vw;
    width: 100%;
}
.album_list .item .title{
    font-size: 14px;
    color: #000000;
    font-weight: 600;
    margin: 4.267vw 0 0;
    text-align: center;
    overflow: hidden;
}
.album_list .item .btn{
    font-size: 10px;
    color: #000000;
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.album_list .item .btn img{
    width: 1.867vw;
    height: 1.6vw;
    margin-left: 1.6vw;
}