.download_header{
text-align: center;
}

.download_header .logo{
    width: 13.3333vw;
    height: 13.3333vw;
    margin: 10.6667vw auto 0;
}
.download_header .name{
    font-size: 4.2667vw;
    color: #000000;
    line-height: 4.2667vw;
    margin-top: 3.2vw;
}
.download_header .title{
    font-size: 3.7333vw;
    color: #666666;
    line-height: 3.7333vw;
    margin-top: 2.6667vw;
}
.download_header .download{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10.6667vw;
    margin: 10.6667vw 0 7.4667vw;
}
.download_header .download img{
    width: 32vw;
    height: 10.6667vw;
   margin: 0 2.6667vw;
   border-radius: 5.3333vw;
   box-shadow: 0px 0px 10px #ddd;
}
.download_banner{
    width: 100vw;
}
