.details{
    padding: 5.333vw;
    background: #fff;
    /* min-height: 50vh; */
}
.details img{
    width: 100%;
}
.title{
    font-size: 6.4vw;
    color: #000000;
    line-height: 7.467vw;
}
.content{
    margin-top: 6.4vw;
}