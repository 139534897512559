.introduce{
    padding: 0 5.333vw;
    width: 100vw;
    box-sizing: border-box;
    background: #fff;
    padding-bottom: 12.8vw;
    overflow: hidden;
}
.introduce .banner{
    width: 100%;
}
.introduce .title{
    font-size: 20px;
    color: #000000;
    line-height: 20px;
    font-weight: 600;
    margin: 8.533vw 0 4.267vw;
}
.introduce .content img{
    width: 100%;
}
.introduce .content{
    line-height: 4.267vw;
    font-size: 3.2vw;
    color: #666666;
    margin: 0;
}
.introduce .content p{
    margin: 0;
    padding: 0;
}
.introduce .news .item{
    margin-top:2.133vw ;
    display: flex;
    justify-content: space-between;
    line-height: 4.8vw;
    height: 4.8vw;
}
.introduce .news .item .time{
    font-size: 3.2vw;
    color: #666666;
    width: 32vw;
}
.introduce .news .item .title{
    font-size: 12px;
    color: #666666;
    flex: 1;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    margin: 0;
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
    font-weight: 400;
}